import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: HVD_Comic_Serif_Pro;
    src: url(./fonts/HVD_Comic_Serif_Pro.otf);
  }
  @font-face {
    font-family: Sofia_Pro_Regular;
    src: url(./fonts/Sofia_Pro_Regular.otf);
  }
  @font-face {
    font-family: Sofia_Pro_Semi_Bold;
    src: url(./fonts/Sofia_Pro_Semi_Bold.otf);
  }
  * {
    font-family: Sofia_Pro_Regular;
  }
  body {
    background: url(../images/bg.png) ${({ theme }) => theme.colors.background} no-repeat fixed center center;
    background-size:cover;

    img {
      height: auto;
      max-width: 100%;
    }
  }
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-thumb {
    background: #991d25;
    border-radius: 8px;
  }
  .white_box {
    box-shadow: -4px 5px 10px 2px rgb(0 0 0 / 20%);
  }
  .menulink  div {
    font-size: 18px !important;
    font-family: Sofia_Pro_Semi_Bold !important;
    text-transform: uppercase;
  }
  div[role="button"] a div {
    text-transform: uppercase;
    font-size: 15px;
    font-family: Sofia_Pro_Semi_Bold !important;
  }
  button div {
    font-family: "HVD_Comic_Serif_Pro";
    font-weight: 400 !important;
    font-size: 18px !important;
    // color: #bd8a3e !important;
  }
  .header_btn {
    padding: 22px 40px;
    font-size: 18px;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset !important;
  }
  button {
    font-family: "HVD_Comic_Serif_Pro" !important;
    font-weight: 400 !important;
  }
  #swap-currency-input input {
    font-weight: 600 !important;
  }
  #swap-currency-output input {
    font-weight: 600 !important;
  }
  @media screen and (max-width: 559px) {
    input[type="number"] {
      margin-top: 15px;
    }
  }
`

export default GlobalStyle
